import { Accordion, Badge, Card, Text } from "@merge-api/merge-javascript-shared";
import { ChecklistStatusValue, IntegrationChecklistData } from "../../../../constants";
import IntegrationChecklistIssueV2 from "./IntegrationChecklistIssueV2";
import { CHECKLIST_VALUE_DISPLAY_DETAILS } from "../constants";

type Props = {
  integrationChecklist: IntegrationChecklistData | undefined;
  integrationID: string;
  fetchIntegrationChecklist: () => void;
};

const IntegrationChecklistResultsContainerV2 = ({
  integrationChecklist,
  integrationID,
  fetchIntegrationChecklist,
}: Props) => {
  const integrationChecklistIssues = integrationChecklist
    ? Object.entries(integrationChecklist).filter(([_, value]) => {
        return Object.keys(CHECKLIST_VALUE_DISPLAY_DETAILS).includes(value);
      })
    : undefined;

  return (
    <div className="-my-4">
      {" "}
      {/* This is due to the padding placed on the parent AccordionCard */}
      {integrationChecklist && integrationChecklistIssues ? (
        <div className="divide-y divide-solid divide-gray-10">
          {Object.entries(CHECKLIST_VALUE_DISPLAY_DETAILS)
            .filter(([key]) => {
              const filteredIssues = integrationChecklistIssues?.filter(([_, v]) => v === key);
              return filteredIssues && filteredIssues.length > 0;
            })
            .map(([key, value]) => {
              // Filter the checklist issues for the current key
              const filteredIssues = integrationChecklistIssues?.filter(([_, v]) => v === key);

              // Only render the section if there are filtered issues
              return (
                <div className="py-4">
                  <Accordion
                    title={
                      <div className="flex flex-row items-center space-x-2">
                        <Text variant="h6">{value.title}</Text>
                        <Badge color={value.badgeColor}>{filteredIssues.length}</Badge>
                      </div>
                    }
                    defaultExpanded={key === ChecklistStatusValue.NOT_IMPLEMENTED}
                    chevronOrientation="right"
                    key={key}
                    variant="none"
                  >
                    <div className="space-y-2 mt-2">
                      {filteredIssues.map(([k, v]) => (
                        <IntegrationChecklistIssueV2
                          key={k}
                          checklistItem={k}
                          checklistValue={v}
                          integrationID={integrationID}
                          fetchIntegrationChecklist={fetchIntegrationChecklist}
                        />
                      ))}
                    </div>
                  </Accordion>
                </div>
              );
            })}
        </div>
      ) : (
        <Card>
          <Text>No checklist information available.</Text>
        </Card>
      )}
    </div>
  );
};

export default IntegrationChecklistResultsContainerV2;
