// import { Accordion } from "@merge-api/merge-javascript-shared";
import { ChecklistStatusValue, IntegrationChecklistData } from "../../../../constants";
import { IntegrationTestSuiteExecution } from "../../../../models/MappingTests";
import { BlueprintValidatorResult, PublishModuleInfo } from "../types";
// import IntegrationChecklistAccordionTitleV2 from "./IntegrationChecklistAccordionTitleV2";
import AccordionCard from "../../../integration-builder/shared/AccordionCard";
import IntegrationChecklistResultsContainerV2 from "../IntegrationChecklistResults/IntegrationChecklistResultsContainerV2";
import { AlertTriangle } from "lucide-react";
import MappingTestAccordionTitleV2 from "./MappingTestAccordionTitleV2";
import PublishModuleTestExecutionsContainerV2 from "../MappingTestResults/MappingTestResultsV2";

type Props = {
  blueprintValidatorInfo: Record<string, BlueprintValidatorResult>;
  integrationTestSuiteExecution: IntegrationTestSuiteExecution | null;
  hasFailedFetchingLatestExecution: boolean;
  integrationID: string;
  integrationChecklist: IntegrationChecklistData | undefined;
  onRunTestsForStagedIntegrationVersion: () => void;
  fetchIntegrationChecklist: () => void;
  runAllValidationChecks: () => void;
  refreshIntegrationChecklist: () => void;
  isRunningMappingTests: boolean;
  isRunningValidator: boolean;
  publishModuleInfo: PublishModuleInfo | undefined;
  isRefreshingChecklist: boolean;
  isFetchingLatestTestSuiteExecution: boolean;
};

const PublishModuleChecksCardV2 = ({
  integrationID,
  hasFailedFetchingLatestExecution,
  integrationTestSuiteExecution,
  integrationChecklist,
  onRunTestsForStagedIntegrationVersion,
  fetchIntegrationChecklist,
  //   refreshIntegrationChecklist,
  isRunningMappingTests,
  //   isRefreshingChecklist,
  isFetchingLatestTestSuiteExecution,
}: Props) => {
  const failureCount = integrationChecklist
    ? Object.values(integrationChecklist).filter(
        (value) => value == ChecklistStatusValue.NOT_IMPLEMENTED
      ).length
    : 0;

  return (
    <div className="space-y-4">
      <AccordionCard
        chevronSize={16}
        chevronOrientation="right"
        onChange={function noRefCheck() {}}
        titleIcon={
          failureCount > 0 ? <AlertTriangle className="text-yellow-60" size={16} /> : undefined
        }
        badgeColor="yellow"
        badgeContents={
          failureCount > 0 ? `${failureCount} warning${failureCount > 1 ? "s" : ""}` : undefined
        }
        title="Integration Checklist"
        titleClassName="px-6 py-6"
        defaultExpanded={false}
        variant="shadow"
        className="mb-0"
      >
        <IntegrationChecklistResultsContainerV2
          integrationID={integrationID}
          integrationChecklist={integrationChecklist}
          fetchIntegrationChecklist={fetchIntegrationChecklist}
        />
      </AccordionCard>
      <AccordionCard
        chevronSize={16}
        chevronOrientation="right"
        onChange={function noRefCheck() {}}
        defaultExpanded={false}
        title={
          <MappingTestAccordionTitleV2
            integrationTestSuiteExecution={integrationTestSuiteExecution}
            hasFailedFetchingLatestExecution={hasFailedFetchingLatestExecution}
            onRunTestsForStagedIntegrationVersion={onRunTestsForStagedIntegrationVersion}
            isRunningMappingTests={isRunningMappingTests || isFetchingLatestTestSuiteExecution}
          />
        }
        titleClassName="px-6 py-6"
        variant="shadow"
      >
        <PublishModuleTestExecutionsContainerV2
          integrationID={integrationID}
          integrationTestSuiteExecution={integrationTestSuiteExecution}
          hasFailedFetchingLatestExecution={hasFailedFetchingLatestExecution}
          isRunningMappingTests={isRunningMappingTests || isFetchingLatestTestSuiteExecution}
        />
      </AccordionCard>
    </div>
  );
};

export default PublishModuleChecksCardV2;
